import React from 'react';

import { useInView, useLazyHydration } from '@Hooks';

type HydrateWhenInViewportProps = IntersectionObserverInit & {
  className?: string;
  children: React.ReactNode;
};

export const HydrateWhenInViewport: React.FC<HydrateWhenInViewportProps> = ({
  threshold = 0,
  rootMargin = '200px',
  root,
  className,
  children,
}) => {
  const [ setHydrated, LazyHydrateWrapper, isHydrated ] = useLazyHydration();

  const inViewRef = useInView(
    (isInView) => {
      if (isInView) {
        setHydrated();
      }
    },
    {
      disabled: isHydrated,
      triggerOnlyOnce: true,
      root,
      rootMargin,
      threshold,
    },
  );

  return (
    <LazyHydrateWrapper
      ref={inViewRef}
      className={className}
    >
      {children}
    </LazyHydrateWrapper>
  );
};
